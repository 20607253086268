import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'
import { contactbg, mobileContactbg } from 'images'
import RestaurantDetails from './RestaurantDetails'
import Hours from './Hours'
import Map from './Map'
import SocialMedia from './SocialMedia'
import DownloadApp from './DownloadApp'

export default () => {
  return (
    <CFView maxWidth="2000px" w="100%">
      <MobileScreen>
        <CFView maxWidth="800px" w="100%" column>
          <Map />
          <CFView
            column
            w="100%"
            pt="50px"
            pb="180px"
            mt="-4px"
            image={`url(${mobileContactbg}) bottom / cover no-repeat`}
          >
            <CFView w="100%" mb="200px" column alignCenter start>
              <RestaurantDetails />
              <Hours />
              {/* <SocialMedia /> */}
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px">
          <Map />
          <CFView
            w="100%"
            pv="50px"
            row
            justifyEnd
            alignCenter
            image={`url(${contactbg}) center / cover no-repeat`}
            mt="-5px"
          >
            <CFView w="100%" row justifyAround maxWidth="800px" ml="50px">
              <CFView column>
                <RestaurantDetails />
                {/* <SocialMedia /> */}
              </CFView>
              <Hours />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
