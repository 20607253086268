import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          maxHeight="667px"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          alignCenter
        >
          <CFView textCenter w="100%">
            <CFView mt="20px">
              <CFImage
                w="95%"
                src={mobileHeroText}
                alt="Kumo Sushi Bar hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mv="10px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="690px"
          maxWidth="1400px"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyCenter
          alignStart
          pl="3%"
        >
          <CFImage
            w="90%"
            maxWidth="650px"
            src={heroText}
            alt="Kumo Sushi Bar hero text"
            zIndex={98}
          />
          <CFView mt="20px" mb="40px" pulsate>
            <OrderPickupButton />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
